<script setup lang="ts">
import PrimeMessage, {
  type MessageProps
} from 'primevue/message'
import { computed, useSlots } from '#imports'
import type { LiteralUnion } from 'type-fest'

const props = withDefaults(
  defineProps<MessageProps & {
    title?: string
    message?: string
    variant?: LiteralUnion<'success' | 'info' | 'warn' | 'error' | 'secondary' | 'contrast', string>
    autoHide?: boolean
    life?: number
    closable?: boolean
  }>(), {
    life: 3000,
    closable: true
  }
)

const slots = useSlots()
const hasMessage = computed(() => {
  return Boolean(props.message ?? slots.default)
})
const finalSeverity = computed(() => props.severity ?? props.variant ?? 'info')
</script>

<template>
  <PrimeMessage
    v-if="hasMessage"
    :severity="finalSeverity"
    :sticky="!autoHide"
    :closable
    :life
    :pt:root:data-testid="`Message__root`"
    :pt:icon:data-testid="`Message__icon`"
    :pt:text:data-testid="`Message__text`"
    :pt:closeButton:data-testid="`Message__closeButton`"
    :pt:closeIcon:data-testid="`Message__closeIcon`"
  >
    <strong v-if="props.title">{{ props.title }}<br /></strong>
    <span v-if="message" v-html="message.trim()"></span>
    <span v-else>
      <slot />
    </span>
  </PrimeMessage>
</template>
